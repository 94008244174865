import { Component, HostBinding, OnDestroy } from '@angular/core';
import { combineLatestWith, map, Observable, Subject, takeUntil } from 'rxjs';
import { BankCredentials, Bundle, Session } from '../../shared/interfaces';
import { SessionService } from '../../services/session.service';
import { Store } from '@ngrx/store';
import { NavigationService } from '../../services/navigation.service';
import { GoogleTagManagerService } from '../../services/google-tag-manager.service';
import { selectConfiguredBundleId } from '../../store/configuration/configuration.selectors';
import { updateCompanyComponentGroup } from '../../store/company/company.actions';
import {
    selectPromotedBundles,
    selectSelectedProfession,
    selectSelectedProfessionBranch,
} from '../../store/profession/profession.selectors';
import { FormBuilder, FormGroup } from '@angular/forms';

@Component({
    selector: 'rza-mean-bank-account-data',
    templateUrl: './bank-account-data.component.html',
    styleUrl: './bank-account-data.component.css',
})
export class BankAccountDataComponent implements OnDestroy {
    @HostBinding('class') classes = 'flex flex-col flex-grow';
    private unsubscribe$ = new Subject();
    isBankCredentialFormValid = false;
    agbAccepted = false;
    agbLabel!: string;
    adConsent = false;
    adConsentLabel: string | undefined;

    orderCompleted = false;
    showSpinner = false;
    bundle$: Observable<Bundle | undefined>;
    selectedBundle: Bundle | undefined;
    recommendedByForm: FormGroup;
    selectedProfessionBranch$: Observable<string | undefined>;
    isAlreadySubmitted = {
        tos: false,
        adConsent: false,
    };

    constructor(
        private sessionService: SessionService,
        public store: Store,
        private navigationService: NavigationService,
        private gtmService: GoogleTagManagerService,
        private fb: FormBuilder
    ) {
        this.bundle$ = this.store.select(selectConfiguredBundleId).pipe(
            takeUntil(this.unsubscribe$),
            combineLatestWith(this.store.select(selectPromotedBundles)),
            map(([bundleId, promotedBundles]) => ({
                bundleId,
                promotedBundles,
            })),
            map(({ bundleId, promotedBundles }) => promotedBundles?.bundles.find((bundle) => bundle._id === bundleId))
        );

        this.store
            .select(selectSelectedProfession)
            .pipe(
                takeUntil(this.unsubscribe$),
                map((state) => state.professionBranch),
                combineLatestWith(this.bundle$)
            )
            .subscribe(([professionBranch, bundle]) => {
                this.selectedBundle = bundle;
                this.adConsentLabel = professionBranch.adConsent;
                if (this.selectedBundle?.tos) {
                    this.agbLabel = this.selectedBundle.tos;
                } else if (professionBranch.tos) {
                    this.agbLabel = professionBranch.tos;
                } else {
                    const professionBranchIdentifier = professionBranch.identifier || '';
                    const queryParams: Record<string, string> = {
                        PFLEGE: 'pflege',
                        HIMI: 'himi',
                        HEIMI: 'heimi',
                        TRARETT: 'transport',
                    };
                    this.agbLabel = `Hiermit beauftrage ich die odFin mit dem fortlaufenden
                    Ankauf meiner gegenwärtigen und künftigen Forderungen
                    aus vollständig erbrachten und/oder abrechnungsfähigen
                    Leistungen gegenüber den gesetzlichen Kostenträgern im
                    Rahmen des SGB V und/oder SGB XI im Wege eines
                    unechten Factorings. Der Ankauf wird über das Online
                    Kundencenter der odFin abgewickelt.<br>
                    Hiermit bestätige ich, dass ich die AGB (<a class="underline font-medium text-primary" href="https://www.optadata.de/vertragsunterlagen?branch=${
                        professionBranchIdentifier in queryParams ? queryParams[professionBranchIdentifier] : ''
                    }" target="_blank">Anlage A</a>) und ggf. (<a class="underline font-medium text-primary" href="https://www.optadata.de/vertragsunterlagen?branch=${
                        professionBranchIdentifier in queryParams ? queryParams[professionBranchIdentifier] : ''
                    }" target="_blank">Anlage A1</a>) und die Vereinbarung zur Auftragsverarbeitung
                    (<a class="underline font-medium text-primary" href="https://www.optadata.de/vertragsunterlagen?branch=${
                        professionBranchIdentifier in queryParams ? queryParams[professionBranchIdentifier] : ''
                    }" target="_blank">Anlage B</a>) zur Kenntnis genommen habe und diesen
                    Bedingungen/Vereinbarungen zustimme.<br>
                    Die (<a class="underline font-medium text-primary" href="https://www.optadata.de/vertragsunterlagen?branch=${
                        professionBranchIdentifier in queryParams ? queryParams[professionBranchIdentifier] : ''
                    }" target="_blank">Anlage U</a>) dient der Erfüllung der Informationspflichten
                    der odFIN gemäß Art 13 EU-DSGVO.`;
                }
            });

        this.sessionService.adConsent$.pipe(takeUntil(this.unsubscribe$)).subscribe((consent: boolean) => (this.adConsent = consent));

        this.recommendedByForm = this.fb.group(
            {
                recommendedBy: [''],
            },
            { updateOn: 'blur' }
        );
        this.recommendedByForm.valueChanges.pipe(takeUntil(this.unsubscribe$)).subscribe((form) => {
            this.store.dispatch(updateCompanyComponentGroup.updateRecommendedBy({ recommendedBy: form.recommendedBy }));
        });
        this.selectedProfessionBranch$ = this.store.select(selectSelectedProfessionBranch);
    }

    ngOnDestroy() {
        this.unsubscribe$.next(null);
        this.unsubscribe$.complete();
    }

    updateBankCredentialsForm(bankCredentials: BankCredentials) {
        this.store.dispatch(updateCompanyComponentGroup.updateBankCredentials({ bankCredentials }));
    }

    disableNavigation() {
        return this.isBankCredentialFormValid && this.agbAccepted;
    }

    acceptAgb() {
        if (!this.isAlreadySubmitted.tos) {
            this.isAlreadySubmitted.tos = true;
            this.sessionService.updateAgbAcceptance(this.agbAccepted).subscribe({
                next: () => {
                    this.isAlreadySubmitted.tos = false;
                },
                error: () => {
                    this.isAlreadySubmitted.tos = false;
                    this.agbAccepted = !this.agbAccepted;
                },
            });
        }
    }

    consentAd() {
        if (!this.isAlreadySubmitted.adConsent) {
            this.isAlreadySubmitted.adConsent = true;
            this.sessionService.updateAdConsent(this.adConsent).subscribe({
                next: () => {
                    this.isAlreadySubmitted.adConsent = false;
                },
                error: () => {
                    this.isAlreadySubmitted.adConsent = false;
                    this.adConsent = !this.adConsent;
                },
            });
        }
    }

    complete(): void {
        this.showSpinner = true;
        this.sessionService.complete(this.navigationService.isTestMode).subscribe({
            next: (session: Session) => {
                this.showSpinner = false;
                if (session.isCompleted) {
                    this.gtmService.transactionCompleted();
                    this.orderCompleted = session.isCompleted;
                }
            },
            error: () => {
                this.showSpinner = false;
            },
        });
    }
}
