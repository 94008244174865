import { Component, HostBinding, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { map, Observable, of } from 'rxjs';
import { Store } from '@ngrx/store';
import { selectEmail, selectPhoneNumber } from '../../store/profession/profession.selectors';

@Component({
    selector: 'rza-mean-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
    // @ViewChild('feedbackModal') feedbackModal: ElementRef<HTMLElement> | undefined;
    @HostBinding('class') classes = 'min-h-screen flex flex-col';
    feedbackForm: FormGroup;
    // modalContainer: any;
    phone$: Observable<string | undefined> = of(undefined);
    hrefPhone$: Observable<string | undefined> = of(undefined);
    email$: Observable<string | undefined> = of(undefined);

    constructor(private fb: FormBuilder, private store: Store) {
        this.feedbackForm = this.fb.group({
            email: ['', Validators.email],
            subject: ['', Validators.required],
            message: ['', Validators.required],
        });
    }

    ngOnInit(): void {
        this.phone$ = this.store.select(selectPhoneNumber);
        this.hrefPhone$ = this.phone$.pipe(
            map((phone) => {
                phone = phone?.replace(/ /g, '') || '';
                phone = phone?.replace('-', '');
                if (phone?.length > 0 && phone?.charAt(0) === '0') {
                    phone = phone.replace('0', '+49');
                }
                return phone;
            })
        );
        this.email$ = this.store.select(selectEmail);
    }

    // openFeedbackModal() {
    //     this.sessionService
    //         .hasSession()
    //         .pipe(
    //             mergeMap((hasSession) => (hasSession ? of(null) : this.sessionService.init(null, true))),
    //             take(1)
    //         )
    //         .subscribe(() => {
    //             this.modalContainer = this.modalService.open('Nehmen Sie mit uns Kontakt auf.', this.feedbackModal);
    //         });
    // }

    // closeModal() {
    //     this.modalContainer.close.emit(false);
    // }

    // sendFeedback() {
    //     this.apiService.sendFeedback(this.feedbackForm.value).subscribe({
    //         next: (feedback) => {
    //             this.closeModal();
    //         },
    //     });
    // }
}
